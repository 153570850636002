import { Flex, Spinner } from '@chakra-ui/react';
import { Text } from '~/design-system/text';

export const ContainerLoading = (props: { text?: string }) => {
  return (
    <Flex
      width='full'
      height='full'
      justifyContent='center'
      alignItems='center'
      flexDirection='column'
      gap='8'
    >
      <Spinner size='xl' />
      {props.text ? <Text>{props.text}</Text> : null}
    </Flex>
  );
};
